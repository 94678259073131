import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  border: 1px solid ${props => props.theme.secondaryColor};
  border-radius: 50px;
  padding: 10px 20px;
  justify-content: space-between;

  .pageNumber {
    color: #848891;
  }
`

const Link = styled.a`
  color: ${props => props.disabled ? "#BABCB3" : props.theme.secondaryColor}
`

export default props => {
  const { paginationData } = props
  return (
    <Container className="d-flex align-items-center m-auto" style={{ maxWidth: "250px" }}>
      <Link href={paginationData?.prevPath || "#"} disabled={paginationData?.prevPath ? undefined : 1} className="d-flex align-items-center">
        <svg width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="mr-2">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-605.000000, -2261.000000)">
                  <g transform="translate(97.000000, 579.000000)">
                      <g transform="translate(486.000000, 1664.000000)">
                          <g transform="translate(18.000000, 16.000000)" fill="#81858B" opacity="0.5">
                              <g transform="translate(0.000000, 1.000000)">
                                  <g transform="translate(8.500000, 8.500000) rotate(90.000000) translate(-8.500000, -8.500000) ">
                                      <path d="M12.0305955,2.37690446 C11.6835122,2.02982113 11.1239289,2.02982113 10.7768455,2.37690446 L4.89059554,8.26315446 C4.61434554,8.53940446 4.61434554,8.98565446 4.89059554,9.26190446 L10.7768455,15.1481545 C11.1239289,15.4952378 11.6835122,15.4952378 12.0305955,15.1481545 C12.3776789,14.8010711 12.3776789,14.2414878 12.0305955,13.8944045 L6.9022622,8.7589878 L12.0376789,3.62357113 C12.3776789,3.28357113 12.3776789,2.71690446 12.0305955,2.37690446 Z" transform="translate(8.487604, 8.762529) scale(1, -1) rotate(90.000000) translate(-8.487604, -8.762529) "></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
        Prev
      </Link>
      <span className="pageNumber">{paginationData?.currentPage} / {paginationData?.numberOfPages}</span>
      <Link href={paginationData?.nextPath || "#"} disabled={paginationData?.nextPath ? undefined : 1} className="d-flex align-items-center">
        Next
        <svg width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="ml-2">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-830.000000, -2261.000000)">
                    <g transform="translate(97.000000, 579.000000)">
                        <g transform="translate(486.000000, 1664.000000)">
                            <g transform="translate(243.000000, 17.000000)" fill="#7857FF" opacity="0.5">
                                <g transform="translate(8.500000, 8.500000) scale(-1, 1) rotate(90.000000) translate(-8.500000, -8.500000) ">
                                    <path d="M12.0305955,2.37690446 C11.6835122,2.02982113 11.1239289,2.02982113 10.7768455,2.37690446 L4.89059554,8.26315446 C4.61434554,8.53940446 4.61434554,8.98565446 4.89059554,9.26190446 L10.7768455,15.1481545 C11.1239289,15.4952378 11.6835122,15.4952378 12.0305955,15.1481545 C12.3776789,14.8010711 12.3776789,14.2414878 12.0305955,13.8944045 L6.9022622,8.7589878 L12.0376789,3.62357113 C12.3776789,3.28357113 12.3776789,2.71690446 12.0305955,2.37690446 Z" transform="translate(8.487604, 8.762529) scale(1, -1) rotate(90.000000) translate(-8.487604, -8.762529) "></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </Link>
    </Container>
  )
}
