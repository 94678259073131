import React from "react"
import Layout from "../../components/layout"
import { trackWindowScroll } from "react-lazy-load-image-component"
import { Grid, Row, Col } from "react-flexbox-grid"
import Breadcrumbs from "../../components/breadcrumbs"
import { HeroSection, StyledSection } from "../../components/styledComponents"
import PostList from "../../components/postList"
import PostGrid from "../../components/postGrid"
import { dynamicString } from "../../../utils/index"
import { StickyContainer } from "react-sticky"
import TabBar from "../../components/tabBar"
import Pagination from "../../components/pagination"

const News = (props) => {
  const { pageContext, scrollPosition } = props
  const { site, modules = {}, taxonomy = {}, pagination = {} } = pageContext
  const { featuredPosts = [], menu, postList = [] } = modules

  const title =
    dynamicString({
      inputString: site["featured-news-heading"],
    }) || taxonomy?.name || "News"

  return (
    <Layout
      {...props}
      headerBackground={"#fff"}
      footerLight
      htmlTitle={
        (taxonomy?.["html-title"] || title || taxonomy?.name) +
        (pagination?.currentPage > 1
          ? ` - Page ${pagination?.currentPage}`
          : "")
      }
      metaDescription={
        taxonomy?.["meta-description"] +
        (pagination?.currentPage > 1
          ? ` | Page ${pagination?.currentPage}`
          : "")
      }
      path={taxonomy.path}
      scrollPosition={scrollPosition}
    >
      <HeroSection hero pattern={1}>
        <Grid>
          <Breadcrumbs {...props} invert={1} />
          <Row className="py-2 py-md-5">
            <Col xs={12} md={7} className="pr-lg-5">
              <h1 className={pagination?.currentPage > 1 ? "mb-5" : ""}>
                {title}
                {pagination?.currentPage > 1
                  ? ` - Page ${pagination?.currentPage}`
                  : null}
              </h1>
              {parseInt(pagination.currentPage) === 1 ? (
                <div
                  dangerouslySetInnerHTML={{ __html: taxonomy["content"] }}
                />
              ) : null}
            </Col>
            <Col xs={12} md={5}>
              <PostList
                {...props}
                posts={featuredPosts}
                scrollPosition={scrollPosition}
                className="my-3 px-0"
                count={2}
                h2titles={true}
              />
            </Col>
          </Row>
        </Grid>
      </HeroSection>

      {menu?.length ? (
        <TabBar {...props} menu={menu} className="" activeTab={false} />
      ) : null}

      <StyledSection className="pb-5">
        <Grid>
          <StickyContainer>
            <Row>
              <Col xs={12}>
                <PostGrid
                  {...props}
                  posts={postList}
                  scrollPosition={scrollPosition}
                  className="mt-0 mb-3"
                  multiline={true}
                  h2titles={true}
                />
                <Pagination paginationData={pagination} />
              </Col>
            </Row>
          </StickyContainer>
        </Grid>
      </StyledSection>
    </Layout>
  )
}

export default trackWindowScroll(News)
